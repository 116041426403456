import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Reactmarkdown from 'react-markdown'

import PageLayout from '../components/page-layout'

const AboutPage = ({
  data: {
    wpPage: {
      pageAbout: {
        mainContent,
        middleContentLeft,
        middleContentRight,
        bottomContent,
      },
    },
  },
}) => {
  const [accordianActive, setAccordianActive] = useState(false)

  return (
    <PageLayout title='About'>
      {mainContent && (
        <div
          className='content mb-0'
          dangerouslySetInnerHTML={{ __html: mainContent }}
        />
      )}
      {(middleContentLeft || middleContentRight) && (
        <div className='card mb-5'>
          <header className='card-header'>
            <p className='card-header-title is-size-4 has-text-weight-semibold'>
              Categories
            </p>
            <a
              onClick={() => setAccordianActive(!accordianActive)}
              className='card-header-icon is-hidden-fullscreen'
            >
              <span className='icon'>
                <i className='fas fa-angle-down'></i>
              </span>
            </a>
          </header>
          {accordianActive && (
            <div className='card-content'>
              <div className='columns'>
                <div
                  className='column content py-0 mb-0'
                  dangerouslySetInnerHTML={{ __html: middleContentLeft }}
                />
                <div
                  className='column content py-0 mb-0'
                  dangerouslySetInnerHTML={{ __html: middleContentRight }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {bottomContent && (
        <div
          className='content mb-0'
          dangerouslySetInnerHTML={{ __html: bottomContent }}
        />
      )}
    </PageLayout>
  )
}

export const query = graphql`
  query AboutPageQuery {
    wpPage(slug: { eq: "about" }) {
      pageAbout {
        mainContent
        middleContentLeft
        middleContentRight
        bottomContent
      }
    }
  }
`

export default AboutPage
